import { getFeatureFlagValue } from './helper';
import { IDispatchManagerFeatures } from './interface';

export const dispatchManagerFeatures: IDispatchManagerFeatures = {
    isHideIntimetecMailIdUsers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_HIDE_INTIMETEC_MAIL_ID_USERS,
    ),
    isShowSettingsInvoicing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_SETTINGS_INVOICING,
    ),
    isShowInputAndDocumentPreferences: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_INPUT_AND_DOCUMENT_PREFERENCES,
    ),
    isShowTrailerIncomeReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_TRAILER_INCOME_REPORT,
    ),
    isShowActivityLog: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_ACTIVITY_LOG,
    ),
    isShowSupportTicketButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_SUPPORT_TICKET_BUTTON,
    ),
    isShowSubscriptionComponent: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_SUBSCRIPTION_COMPONENT,
    ),
    isShowResetPasswordChecklist: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_RESET_PASSWORD_CHECKLIST,
    ),
    isShowConnectToQuickbooks: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_CONNECT_TO_QUICKBOOKS,
    ),
    isShowPasswordResetFrequency: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_PASSWORD_RESET_FREQUENCY,
    ),
    isShowInvoiceTotalAndDriverRate: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_INVOICE_TOTAL_DRIVER_RATE,
    ),
    isShowAllActivityItems: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_ALL_ACTIVITY_ITEMS,
    ),
};
