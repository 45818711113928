import { AssociateType } from '../../components/Associate/enum';
import { ShipmentHistoryUtils } from '../../components/ShipmentHistory/utils/ShipmentHistoryUtils';
import { buildFetchShipmentDetailsApiUrl } from '../../pages/LoadBoard/action';
import {
    ILoadBoardSearchFilters,
    ILoadBoardStatusFilters,
} from '../../pages/LoadBoard/interfaces';
import { API_CONSTANTS } from '../../services/apiConstants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { Routes } from '../../utils/routes';
import { apiCall } from '../axios';
import { IDriverCard } from '../driver/interfaces';
import { IAttachmentResponse } from '../interfaces';
import { ISelectedDriverIdList } from '../../pages/LoadBoard/interfaces/ISelectedDriverList';
import {
    IPindrop,
    IShipment,
    IShipmentLoadBoardViewItem,
    IShipmentPayload,
} from './interfaces';
import { IInvoicePreview } from './interfaces/IInvoicePreview';

const updateShipmentStatus = async (
    shipmentId: number,
    selectedDriverIdList: ISelectedDriverIdList[],
): Promise<void> => {
    await apiCall(
        'post',
        `${API_CONSTANTS.shipments}${API_CONSTANTS.dispatch}/${shipmentId}`,
        selectedDriverIdList,
        true,
    );
};

const updateShipment = async (
    shipmentId: number,
    payload: IShipmentPayload,
): Promise<void> => {
    await apiCall(
        'put',
        `${API_CONSTANTS.shipments}/${shipmentId}`,
        payload,
        true,
    );
};

const addDriverLoad = async (
    shipmentId: number,
    load: IDriverCard,
    numberOfTrucks = 1,
): Promise<number[]> => {
    return await apiCall(
        'post',
        `${API_CONSTANTS.shipments}/${shipmentId}${API_CONSTANTS.loads}`,
        { ...load, numberOfTrucks },
        true,
    );
};

export const fetchFullShipmentDetails = async (
    shipmentId: number | string,
): Promise<IShipment> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.shipments}/${shipmentId}?include=Attachments, Associate, Associate.Contacts, Associate.Address, Commodity, ShipmentLocations, ShipmentLocations.Location, ShipmentLocations.Location.TagGroup, ShipmentLocations.PinDrop, DriverLoads, DriverLoads.Associate, DriverLoads.Associate.Contacts, DriverLoads.DriverLoadCharges, DriverLoads.Attachments, ShipmentStatus, ShipmentCargoDetails.Commodity, ShipmentCargoDetails, ShipmentCargoDetails.ShipmentCargoLocations, DriverLoads.DriverLoadCargoDetails, DriverLoads.DriverLoadCargoDetails.Commodity, DriverLoads.DriverLoadCargoDetails.DriverLoadCargoLocations`,
        {},
        true,
    );

    return response.data;
};

const fetchShipmentDetails = async (
    loadBoardSearchFilters: ILoadBoardSearchFilters,
    loadBoardBadgesFilters: ILoadBoardStatusFilters,
): Promise<IShipmentLoadBoardViewItem[]> => {
    const apiUrl: string = buildFetchShipmentDetailsApiUrl(
        loadBoardSearchFilters,
        loadBoardBadgesFilters,
    );

    const response: IGenericApiResponse<IShipmentLoadBoardViewItem[]> =
        await apiCall('get', apiUrl, {}, true);

    return response.data;
};

export const fetchFullShipmentHistory = async (
    associateId: string | number,
    associateType: AssociateType,
    deliveryStartDate?: string | Date,
    deliveryEndDate?: string | Date,
): Promise<IShipment[]> => {
    const shipmentHistoryUtils = new ShipmentHistoryUtils();
    let url = `${API_CONSTANTS.shipments}/odata?`;

    url += shipmentHistoryUtils.shipmentHistoryFilterQuery(
        associateId,
        associateType,
        deliveryStartDate,
        deliveryEndDate,
    );

    const response: IGenericApiResponse<IShipment[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );

    return response.data;
};

const fetchPinDropsSuggestions = async (
    suggestion: string,
): Promise<IPindrop[]> => {
    const response: IGenericApiResponse<IPindrop[]> = await apiCall(
        'get',
        `${API_CONSTANTS.pindrops}?include=location&sort=name&filter=name like ${suggestion}`,
        {},
        true,
    );
    return response.data;
};

const fetchAssociateShipmentDetail = async (
    associateId: number,
    associateRouteType: string,
): Promise<IShipment[]> => {
    let url = `${API_CONSTANTS.shipments}/odata?include=ShipmentLocations,ShipmentLocations.Location,ShipmentLocations.Location.TagGroup,Associate,ShipmentStatus,DriverLoads,DriverLoads.Associate`;
    let filterString = `IsActive eq true`;

    if (associateRouteType === Routes.drivers) {
        filterString += ` and (DriverLoads/any(d: d/Associate/AssociateId eq ${associateId})) and ((ShipmentStatus/Name eq 'NeedsDrivers') or (ShipmentStatus/Name eq 'Ready') or (ShipmentStatus/Name eq 'Dispatched') or (ShipmentStatus/Name eq 'AwaitingConfirmation'))`;
    } else {
        filterString += ` and (Associate/AssociateId eq ${associateId}) and ((ShipmentStatus/Name eq 'NeedsDrivers') or (ShipmentStatus/Name eq 'Ready') or (ShipmentStatus/Name eq 'Dispatched') or (ShipmentStatus/Name eq 'AwaitingConfirmation'))`;
    }

    url += `&$filter=${filterString}`;

    const response: IGenericApiResponse<IShipment[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );
    return response.data;
};

export const fetchShipmentAttachment = async (
    shipmentId: number,
    attachment: IAttachmentResponse,
): Promise<IGenericApiResponse<Blob>> => {
    const response: IGenericApiResponse<Blob> = await apiCall(
        'get',
        `${API_CONSTANTS.shipments}/${shipmentId}${API_CONSTANTS.attachments}/${attachment.attachmentId}/${attachment.accessToken}`,
        {},
        true,
        true,
    );

    return response;
};

const fetchAssociateBreakdownDriverShipmentDetail = async (
    associateId: number,
): Promise<IShipment[]> => {
    const baseIncludeQuery = `ShipmentLocations,ShipmentLocations.Location,ShipmentLocations.Location.TagGroup,Associate,ShipmentStatus,DriverLoads,DriverLoads.Associate`;
    const baseFilter = `IsActive eq true and (DriverLoads/any(driverLoad: driverLoad/AssociateId eq ${associateId} and driverLoad/status eq 'Dispatched'))`;

    const url = `${
        API_CONSTANTS.shipments
    }/odata?include=${baseIncludeQuery}&$filter=${encodeURIComponent(
        baseFilter,
    )}`;

    const response: IGenericApiResponse<IShipment[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );
    return response.data;
};

const fetchShimpentInvoicePreviewDetails = async (
    shipmentId: number,
): Promise<IInvoicePreview> => {
    const response: IGenericApiResponse<IInvoicePreview> = await apiCall(
        'get',
        `${API_CONSTANTS.shipments}${API_CONSTANTS.invoicePreview}/${shipmentId}`,
        {},
        true,
    );
    return response.data;
};

export const shipmentsApi = {
    updateShipmentStatus,
    fetchShipmentDetails,
    addDriverLoad,
    fetchFullShipmentDetails,
    updateShipment,
    fetchFullShipmentHistory,
    fetchPinDropsSuggestions,
    fetchAssociateShipmentDetail,
    fetchShipmentAttachment,
    fetchAssociateBreakdownDriverShipmentDetail,
    fetchShimpentInvoicePreviewDetails,
};
