import { IQBUser } from '../../pages/Settings/components/CommunicationAndInvoicePreferences/interfaces/IQBUser';
import { IUser } from '../../pages/Settings/components/CompanyAndUsers/interfaces/IUser';
import { UserListUtils } from '../../pages/Settings/components/CompanyAndUsers/utils/UserListUtils';
import { API_CONSTANTS } from '../../services/apiConstants';
import { ApiCallRequestTypes } from '../../utils/constants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { apiCall } from '../axios';
import { IAttachmentResponse } from '../interfaces';
import { ICompanyProfilePictureDetails } from './interface/ICompanyProfilePictureDetails';
import { ISetting } from './interface/ISetting';

const fetchSettings = async (): Promise<ISetting[]> => {
    const response: IGenericApiResponse<ISetting[]> = await apiCall(
        'get',
        API_CONSTANTS.setting,
        {},
        true,
    );

    return response.data;
};

const updateSettings = async (settings: ISetting[]): Promise<void> => {
    await apiCall('put', `${API_CONSTANTS.setting}`, settings, true);
};

export const uploadCompanyProfilePicture = async (
    companyProfilePictureDetails: ICompanyProfilePictureDetails,
): Promise<IAttachmentResponse> => {
    const response: IGenericApiResponse<IAttachmentResponse> = await apiCall(
        'post',
        `${API_CONSTANTS.setting}${API_CONSTANTS.attachments}`,
        companyProfilePictureDetails,
        true,
        false,
        true,
    );

    return response.data;
};

const fetchCompanyProfilePicture = async (
    baseSettingId: number,
    accessToken: string,
    attachmentId: number,
): Promise<IGenericApiResponse<Blob>> => {
    const response: IGenericApiResponse<Blob> = await apiCall(
        'get',
        `${API_CONSTANTS.setting}/${baseSettingId}${API_CONSTANTS.attachments}/${attachmentId}/${accessToken}`,
        {},
        true,
        true,
    );

    return response;
};

const fetchUsersList = async (
    tenantId: string,
    userName?: string,
    userStatus?: boolean,
    roleFilter?: string,
): Promise<IUser[]> => {
    const userListUtils = new UserListUtils();

    let url = `${API_CONSTANTS.users}/odata?include=Roles&orderby=enabled desc,firstName asc`;

    url += userListUtils.userListFilterQueryString(
        tenantId,
        userName,
        userStatus,
        roleFilter,
    );

    const response = await apiCall('get', url, {}, true);
    return response.data;
};

export const createUser = async (
    user: IUser,
    tenantId: string,
): Promise<number> => {
    const response = await apiCall(
        'post',
        `${API_CONSTANTS.users}?tenantId=${tenantId}&template=Invitation&verified=false`,
        { ...user },
        true,
    );

    return response.data;
};

export const updateUser = async (user: IUser): Promise<number> => {
    const response = await apiCall(
        'put',
        `${API_CONSTANTS.users}/${user.userId}`,
        { ...user },
        true,
    );

    return response.data;
};

export const downloadQBConnectionFile = async (
    fileType: string,
): Promise<Blob> => {
    const response: IGenericApiResponse<Blob> = await apiCall(
        'get',
        `${API_CONSTANTS.setting}${API_CONSTANTS.download}?fileType=${fileType}`,
        {},
        true,
        true,
        false,
        ApiCallRequestTypes.excelFormat,
    );
    return response.data;
};

export const createQuickBooksUser = async (
    userName: string,
): Promise<number> => {
    const response = await apiCall(
        'post',
        `${API_CONSTANTS.setting}${API_CONSTANTS.qbuser}`,
        `"${userName}"`,
        true,
    );
    return response.data;
};

const fetchQBUsersList = async (): Promise<IQBUser[]> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.setting}${API_CONSTANTS.qbusers}`,
        {},
        true,
    );
    return response.data;
};

export const settingsApi = {
    fetchSettings,
    updateSettings,
    uploadCompanyProfilePicture,
    fetchCompanyProfilePicture,
    fetchUsersList,
    createUser,
    updateUser,
    downloadQBConnectionFile,
    createQuickBooksUser,
    fetchQBUsersList,
};
