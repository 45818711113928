import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../../../api/apiError';
import { settingsApi } from '../../../../../api/settings/settingsApi';
import {
    INSTRUCTIONS_FILE,
    INSTRUCTIONS_FILE_NAME,
    QWC_CONNECTION_FILE,
    QWC_CONNECTION_FILE_NAME,
    ROD_INVOICE_TEMPLATE_NAME,
} from '../../../constants';

export const downloadQBConnectionFile = createAsyncThunk(
    'downloadQBConnectionFile',
    async (fileType: string, { rejectWithValue }) => {
        try {
            const response = await settingsApi.downloadQBConnectionFile(
                fileType,
            );
            if (response) {
                const fileBlob = new Blob([response], {
                    type: 'application/pdf',
                });
                const link = document.createElement('a');
                const fileURL = URL.createObjectURL(fileBlob);
                link.href = fileURL;
                if (fileType === INSTRUCTIONS_FILE) {
                    link.download = INSTRUCTIONS_FILE_NAME;
                } else if (fileType === QWC_CONNECTION_FILE) {
                    link.download = QWC_CONNECTION_FILE_NAME;
                } else {
                    link.download = ROD_INVOICE_TEMPLATE_NAME;
                }
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
            }
            return;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export interface IDownloadQBConnetionFileInitialState {
    loading: boolean;
    status: APIStatus;
}

const initialState: IDownloadQBConnetionFileInitialState = {
    loading: false,
    status: APIStatusOK,
};

export const downloadQBConnectionFileSlice = createSlice({
    name: 'downloadQBConnectionFile',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(downloadQBConnectionFile.pending, state => {
            state.loading = true;
        });
        builder.addCase(downloadQBConnectionFile.fulfilled, state => {
            state.loading = false;
        });

        builder.addCase(downloadQBConnectionFile.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

const QBConnectionFileReducer = downloadQBConnectionFileSlice.reducer;
export default QBConnectionFileReducer;
