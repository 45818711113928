import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reportApi } from '../../../api/report/reportApi';
import { NullableDate } from '../../../utils/interfaces';
import { ISettlementReport } from '../../../api/report/interface/ISettlementReport';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { SettlementReportData } from '../components/InvoiceMobileReport/GenerateReport/components/SettlementsReportDetails/interfaces/ISettlementsReportData';
import { dateFormatter, toCamelCase } from '../../../utils/functions';

export const fetchSettlementReport = createAsyncThunk(
    'fetchSettlementReport',
    async (
        {
            startDate,
            endDate,
        }: { startDate: NullableDate; endDate: NullableDate },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const formattedStartDate = dateFormatter(startDate || '');
            const formattedEndDate = dateFormatter(endDate || '');
            const response = await reportApi.fetchSettlementReport({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            const formattedSettlementReportDetail =
                getFormattedSettlementReportDetail(response);
            dispatch(
                setSettlementReportDetail(formattedSettlementReportDetail),
            );

            return formattedSettlementReportDetail;
        } catch {
            return rejectWithValue('error');
        }
    },
);

const getFormattedSettlementReportDetail = (
    data: ISettlementReport[],
): SettlementReportData => {
    return data?.map(report => ({
        driverName: report.driverName,
        driverId: report.driverId,
        overallTotalEarnings: report.overallTotalEarnings?.toFixed(1),
        driverLoadsDetail: report.driverLoadsDetail?.map(load => ({
            driverLoadId: load.driverLoadId,
            loadDetail: [
                {
                    name: 'loadNumber',
                    value: load.loadNumber,
                    columnIndex: 1,
                },
                {
                    name: 'pickupDate',
                    value: load.pickupDate?.replaceAll('-', '/'),
                    columnIndex: 2,
                },
                {
                    name: 'dropoffDate',
                    value: load.dropoffDate?.replaceAll('-', '/'),
                    columnIndex: 3,
                },
                {
                    name: 'pickupLocation',
                    value: load?.pickupLocation?.replaceAll('to', ';'),
                    columnIndex: 4,
                },
                {
                    name: 'dropoffLocation',
                    value: load?.dropoffLocation?.replaceAll('to', ';'),
                    columnIndex: 5,
                },
            ],
            loadSettlementDetail: load.earnings
                ?.map(earning => ({
                    loadEarning: toCamelCase(earning.description) || '',
                    weight: earning.weight
                        ? earning.weight
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0.0',
                    units: earning.units ? earning.units?.toFixed(2) : '0.0',
                    rate: earning.rate ? earning.rate?.toFixed(2) : '0.0',
                    payment: earning.payment?.toFixed(2),
                }))
                .concat([
                    {
                        loadEarning: '',
                        weight: '',
                        units: '',
                        rate: '',
                        payment: load.totalEarnings?.toFixed(2),
                    },
                ]),
        })),
    }));
};

interface ISettlementReportInitialState {
    loading: boolean;
    settlementReportDetail: SettlementReportData;
    status: APIStatus;
}

const initialState: ISettlementReportInitialState = {
    loading: false,
    settlementReportDetail: [],
    status: APIStatusOK,
};

export const fetchSettlementReportSlice = createSlice({
    name: 'fetchSettlementReport',
    initialState,
    reducers: {
        setSettlementReportDetail: (
            state,
            action: PayloadAction<SettlementReportData>,
        ) => {
            state.settlementReportDetail = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSettlementReport.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchSettlementReport.fulfilled, (state, action) => {
            state.loading = false;
            state.settlementReportDetail = action.payload;
        });
        builder.addCase(fetchSettlementReport.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { setSettlementReportDetail } = fetchSettlementReportSlice.actions;

const SettlementReportReducer = fetchSettlementReportSlice.reducer;
export default SettlementReportReducer;
