import Axios, {
    AxiosInstance,
    AxiosRequestConfig,
    HttpStatusCode,
} from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { store } from '../redux/store';
import {
    getBasicAuthToken,
    getRefreshToken,
    signOutUser,
} from '../services/auth';
import { CustomHttpStatusCode } from '../utils/constants/common';
import { AutoCloseTimeoutConstants } from '../utils/constants';
import { APIErrorCode, API_ERROR_MESSAGE, throwAPIError } from './apiError';
import { COMPANY_NAME_ALREADY_EXISTS } from '../utils/constants/responseErrorMessage';

const ERROR_MSG_NULL = 'Something went wrong!';

type Method = 'get' | 'put' | 'post' | 'delete';
type Error = {
    response?: {
        data?: {
            type?: string | number;
            title?: string;
        };
        status?: number;
    };
};

const baseOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: AutoCloseTimeoutConstants.axiosTimeOut,
    withCredentials: true,
};

const axiosOptions = {
    ...baseOptions,
    headers: {
        'Content-type': 'application/json',
    },
};

const axiosOptionsAttachments = {
    ...baseOptions,
    headers: {
        'Content-type': 'multipart/form-data',
    },
};

const createAxiosInstance = (config: AxiosRequestConfig): AxiosInstance => {
    const instance = Axios.create(config);

    instance.interceptors.request.use(
        requestConfig => {
            const token =
                sessionStorage.getItem('token') ||
                localStorage.getItem('token') ||
                store.getState().auth.signInData.token;
            if (token) {
                requestConfig.headers.Authorization = `Bearer ${token}`;
            }
            return requestConfig;
        },
        error => Promise.reject(error),
    );

    return instance;
};

export const createAxiosInstanceAuth = (
    config: AxiosRequestConfig,
): AxiosInstance => {
    const instance = Axios.create(config);

    instance.interceptors.request.use(
        requestConfig => {
            const token = getBasicAuthToken();
            if (token) {
                requestConfig.headers.Authorization = `Basic ${token}`;
            }
            return requestConfig;
        },
        error => Promise.reject(error),
    );

    return instance;
};

const axiosInstance = createAxiosInstance(axiosOptions);
const axiosInstanceBlob = createAxiosInstance({
    ...baseOptions,
    responseType: 'blob',
});
const axiosInstanceAttachments = createAxiosInstance(axiosOptionsAttachments);
export const axiosInstanceAuth = createAxiosInstanceAuth(axiosOptions);

//eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const apiCall = async (
    method: Method,
    url: string,
    payload = {},
    isResRequired = false,
    isResTypeBlob = false,
    uploadFile = false,
    requestType = 'application/json',
) => {
    const instance = isResTypeBlob
        ? axiosInstanceBlob
        : uploadFile
        ? axiosInstanceAttachments
        : axiosInstance;

    try {
        let res;

        if (url.includes('/auth')) {
            res = await instance[method](url, payload);
        } else {
            const headers = {
                Accept: requestType,
            };

            res = await instance.request({
                method,
                url,
                data: payload,
                headers,
            });
        }

        if (
            res &&
            res.status >= HttpStatusCode.Ok &&
            res.status < HttpStatusCode.MultipleChoices
        ) {
            if (res && isResRequired) {
                return res;
            } else {
                return res.data;
            }
        } else {
            throw res;
        }
    } catch (error) {
        const statusCode = handleAxiosCatch(error as Error);
        let errorMessage = '';
        if (Number(statusCode?.status) === HttpStatusCode.Found) {
            errorMessage = getErrorMessage(error as Error).message;
        }
        if (statusCode?.status === HttpStatusCode.Unauthorized) {
            handleUnauthorizedUser();
            notifyError(t('Common:authErrorMessage'));
        } else if (
            Number(statusCode?.status) === HttpStatusCode.UnprocessableEntity
        ) {
            notifyError(t('Common:duplicateEntryMessage'));
        } else if (
            Number(statusCode?.status) === HttpStatusCode.Found &&
            errorMessage === COMPANY_NAME_ALREADY_EXISTS
        ) {
            notifyError(t('Common:companyNameAlreadyExists'));
        } else if (Number(statusCode?.status) === HttpStatusCode.Found) {
            notifyError(t('Common:emailAddressAlreadyExists'));
        } else if (
            Number(statusCode?.status) === HttpStatusCode.UnsupportedMediaType
        ) {
            notifyError(t('Common:unsupportedMediaType'));
        } else if (Number(statusCode?.status) === HttpStatusCode.Locked) {
            notifyError(t('Common:userInactive'));
        } else if (Number(statusCode?.status) === HttpStatusCode.Forbidden) {
            notifyError(t('Common:passwordExpiry'));
        } else if (Number(statusCode?.status) === HttpStatusCode.Conflict) {
            notifyError(t('Common:oldPasswordWarningMessage'));
        } else if (
            Number(statusCode?.status) === HttpStatusCode.MethodNotAllowed
        ) {
            notifyError(t('Common:accessDenied'));
        } else if (
            Number(statusCode?.status) ===
            CustomHttpStatusCode.PublishToServiceBusQueueError
        ) {
            notifyError(t('Common:invoiceDetailsNotSentToQuickBooks'));
        } else if (
            Number(statusCode?.status) ===
                CustomHttpStatusCode.ValidateDriverAssignmentToTrailer &&
            method === 'post'
        ) {
            notifyError(t('Common:ValidateDriverAssignmentToTrailer'));
        } else if (
            Number(statusCode?.status) ===
                CustomHttpStatusCode.ValidateDriverAssignmentToTrailer &&
            method === 'delete'
        ) {
            notifyError(t('Common:deleteTrailerWarning'));
        } else if (
            Number(statusCode?.status) === CustomHttpStatusCode.DuplicateEntry
        ) {
            notifyError(t('Common:duplicateEntryMessage'));
        } else if (
            Number(statusCode?.status) ===
            CustomHttpStatusCode.QuickBooksUserNotCreated
        ) {
            notifyError(t('Common:qbUserNotCreated'));
        } else {
            throwAPIError(statusCode.status);
        }

        return { status: statusCode?.status, error: true };
    }
};

type MessageType = string | undefined | null;
export const notifyError = (
    toastMessage: MessageType = API_ERROR_MESSAGE,
): void => {
    if (!toastMessage) toastMessage = ERROR_MSG_NULL;

    toast.error(toastMessage, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: AutoCloseTimeoutConstants.toasterAutoClose,
    });
};

export const notifySuccess = (message: string): void => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: AutoCloseTimeoutConstants.toasterAutoClose,
    });
};

export const handleAxiosCatch = (error: Error): { status: number } => {
    if (error?.response?.data?.type) {
        const statusCode = isNaN(+error.response.data.type)
            ? error.response.status
            : error.response.data.type;
        if (statusCode) {
            return { status: +statusCode };
        } else {
            return { status: APIErrorCode.Unknown };
        }
    } else if (error?.response?.status) {
        return { status: error.response.status };
    }
    return { status: APIErrorCode.Unknown };
};

export const getErrorMessage = (error: Error): { message: string } => {
    if (error?.response?.data?.type !== undefined) {
        const errorMessage: string = isNaN(+error.response.data.type)
            ? error.response.data.title ?? ''
            : error.response.data.type.toLocaleString();
        if (errorMessage) {
            return { message: errorMessage };
        }
    }
    return { message: '' };
};

const handleUnauthorizedUser = (): void => {
    const refreshTokenExpiry = getLocalRefreshTokenExpiry();
    if (refreshTokenExpiry) {
        const expiry = new Date(refreshTokenExpiry);
        const now = new Date();
        if (expiry > now) {
            getRefreshToken();
        } else {
            signOutUser();
        }
    } else {
        signOutUser();
    }
};

export const getLocalRefreshTokenExpiry = (): string | null => {
    return (
        localStorage.getItem('refreshTokenExpiry') ||
        sessionStorage.getItem('refreshTokenExpiry')
    );
};
