import { API_CONSTANTS } from '../../services/apiConstants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { apiCall } from '../axios';
import { IShipmentLoadBoardViewItem } from '../shipment/interfaces';
import { IDuplicateLoads } from './interfaces/IDuplicateLoads';

const fetchDuplicateLoads = async (): Promise<IDuplicateLoads[]> => {
    const response: IGenericApiResponse<IDuplicateLoads[]> = await apiCall(
        'get',
        `${API_CONSTANTS.activityLog}${API_CONSTANTS.duplicateLoads}`,
        {},
        true,
    );
    return response.data;
};

export const fetchDuplicateShipments = async (
    rodShipmentId: number,
    cpoShipmentId: number,
): Promise<IShipmentLoadBoardViewItem[]> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.shipments}/odata?include=Associate, Associate.Contacts, Associate.Address, Commodity, ShipmentLocations, ShipmentLocations.Location, ShipmentLocations.Location.TagGroup, ShipmentLocations.PinDrop, DriverLoads, DriverLoads.Associate, DriverLoads.Associate.Contacts, DriverLoads.DriverLoadCharges, DriverLoads.Attachments, ShipmentStatus, ShipmentCargoDetails.Commodity, ShipmentCargoDetails, ShipmentCargoDetails.ShipmentCargoLocations, DriverLoads.DriverLoadCargoDetails, DriverLoads.DriverLoadCargoDetails.Commodity, DriverLoads.DriverLoadCargoDetails.DriverLoadCargoLocations&filter = IsActive eq true and (ShipmentId eq ${rodShipmentId} or ShipmentId eq ${cpoShipmentId})`,
        {},
        true,
    );
    return response.data;
};

const confirmNonDuplicateShipments = async (
    rodShipmentId: number,
    cpoShipmentId: number,
): Promise<number> => {
    const response = await apiCall(
        'put',
        `${API_CONSTANTS.activityLog}${API_CONSTANTS.duplicateLoads}/${rodShipmentId}/${cpoShipmentId}`,
        {},
        true,
    );
    return response.data;
};

const confirmDuplicateShipment = async (shipmentId: number): Promise<void> => {
    await apiCall(
        'put',
        `${API_CONSTANTS.shipments}/${shipmentId}`,
        { isActive: false },
        true,
    );
};

export const duplicateLoadsApi = {
    fetchDuplicateLoads,
    fetchDuplicateShipments,
    confirmNonDuplicateShipments,
    confirmDuplicateShipment,
};
