import { IShipmentLoadBoardViewItem } from '../../../../api/shipment/interfaces';
import {
    InvoiceType,
    PriorityType,
} from '../../../../components/Associate/enum';

export const DRAFT_SHIPMENT_DETAILS_INITIAL_STATE: IShipmentLoadBoardViewItem =
    {
        shipmentId: 0,
        customerId: 0,
        customer: {
            name: '',
            associateType: 'Customer',
            phoneNumber: '',
            faxNumber: '',
            email: '',
            notes: '',
            invoiceType: InvoiceType.brokenOut,
            autoShrinkPercentage: 0,
            priority: PriorityType.normalPriority,
            contacts: [],
            attachments: [],
            brokerFeeType: 'Percentage',
            brokerFeeAmount: '',
            address: {
                locationId: 0,
                type: 'Home',
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                latitude: 0,
                longitude: 0,
                instructions: '',
            },
        },
        shipmentLocations: [],
        driverLoads: [],
        isCattlePORequest: false,
        isNew: false,
        loadNumber: 0,
        deliveryEndDate: '',
        deliveryStartDate: '',
        _49kTrucksRequired: 0,
        _52kTrucksRequired: 0,
        _55_62kTrucksRequired: 0,
        status: '',
        contractNumber: '',
        estimatedDistanceInMiles: 0,
        loadTime: '',
        timezone: '',
        weightInPounds: 0,
        unloadedWeightInPounds: 0,
        shrinkPercentage: 0,
        orderNotes: '',
        driverInstructions: '',
        shipmentCargoDetails: [],
        areAllRatesVerified: false,
        isActive: true,
        createdBy: '',
        createdDateTime: '',
    };
