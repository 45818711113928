import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { duplicateLoadsApi } from '../../../../../../../api/activityTracker/duplicateLoads';
import { IShipmentLoadBoardViewItem } from '../../../../../../../api/shipment/interfaces';
import { DRAFT_SHIPMENT_DETAILS_INITIAL_STATE } from '../../../../../../LoadBoard/components/ShipmentDetails/initialState';
import { IDuplicateLoads } from '../../../../../../../api/activityTracker/interfaces/IDuplicateLoads';

export const fetchDuplicateLoadsList = createAsyncThunk(
    'fetchDuplicateLoadsList',
    async (_, { rejectWithValue }) => {
        try {
            const response = await duplicateLoadsApi.fetchDuplicateLoads();
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const fetchDuplicateShipments = createAsyncThunk(
    'fetchDuplicateShipments',
    async (
        {
            rodShipmentId,
            cpoShipmentId,
        }: {
            rodShipmentId: number;
            cpoShipmentId: number;
        },
        { rejectWithValue },
    ) => {
        try {
            const response = await duplicateLoadsApi.fetchDuplicateShipments(
                rodShipmentId,
                cpoShipmentId,
            );
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const SELECTED_INITIAL_STATE: IDuplicateLoads = {
    customerName: '',
    deliveryStartDate: '',
    loadTime: '',
    rodShipmentId: 0,
    cpoShipmentId: 0,
};

export type IDuplicateLoadsInitialState = {
    duplicateLoadLoading: boolean;
    duplicateLoadsList: IDuplicateLoads[];
    duplicateShipments: IShipmentLoadBoardViewItem[];
};

const initialDuplicateLoadsState: IDuplicateLoadsInitialState = {
    duplicateLoadLoading: false,
    duplicateLoadsList: [],
    duplicateShipments: [],
};

export const duplicateLoadsSlice = createSlice({
    name: 'duplicateLoads',
    initialState: initialDuplicateLoadsState,
    reducers: {
        setDuplicateLoadsList: (
            state,
            action: PayloadAction<IDuplicateLoads[]>,
        ) => {
            state.duplicateLoadsList = action.payload;
        },
        setDuplicateShipments: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem[]>,
        ) => {
            state.duplicateShipments = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDuplicateLoadsList.pending, state => {
            state.duplicateLoadLoading = true;
            state.duplicateLoadsList = [];
        });
        builder.addCase(fetchDuplicateLoadsList.fulfilled, (state, action) => {
            state.duplicateLoadLoading = false;
            state.duplicateLoadsList = action.payload;
        });
        builder.addCase(fetchDuplicateLoadsList.rejected, state => {
            state.duplicateLoadLoading = false;
            state.duplicateLoadsList = [SELECTED_INITIAL_STATE];
        });
        builder.addCase(fetchDuplicateShipments.pending, state => {
            state.duplicateLoadLoading = true;
            state.duplicateShipments = [];
        });
        builder.addCase(fetchDuplicateShipments.fulfilled, (state, action) => {
            state.duplicateLoadLoading = false;
            state.duplicateShipments = action.payload;
        });
        builder.addCase(fetchDuplicateShipments.rejected, state => {
            state.duplicateLoadLoading = false;
            state.duplicateShipments = [DRAFT_SHIPMENT_DETAILS_INITIAL_STATE];
        });
    },
});

export const { setDuplicateLoadsList, setDuplicateShipments } =
    duplicateLoadsSlice.actions;

const DuplicateLoadsReducer = duplicateLoadsSlice.reducer;
export default DuplicateLoadsReducer;
