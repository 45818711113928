import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { ITrailerDetail } from '../../../api/trailer/interface/ITrailerDetail';
import { trailerApi } from '../../../api/trailer/trailerApi';
import { TRAILER_DETAIL_INITIAL_STATE } from '../initialState';
import { IReassignTrailer } from '../components/DispatchTrailer/ManageTrailers/interface/IReassignTrailer';

export const fetchTrailerDetail = createAsyncThunk(
    'fetchTrailerDetail',
    async ({ trailerId }: { trailerId: number }, { rejectWithValue }) => {
        try {
            const trailerDetailResponse = await trailerApi.fetchTrailerDetail(
                trailerId,
            );

            const formattedTrailerDetail: ITrailerDetail = {
                trailerId: trailerDetailResponse.trailerId,
                trailerNumber: trailerDetailResponse.trailerNumber,
                trailerSize: trailerDetailResponse?.trailerSize,
                currentDriverId:
                    trailerDetailResponse?.driverTrailers[0]?.driverId || 0,
                driverName:
                    trailerDetailResponse?.driverTrailers?.length > 0
                        ? trailerDetailResponse?.driverTrailers[0]?.driver.name
                        : '',
                companyPercent: trailerDetailResponse.companyPercent,
                rentPercent: trailerDetailResponse.rentPercent,
                isDeleted: trailerDetailResponse.isDeleted,
            };

            return formattedTrailerDetail;
        } catch {
            return rejectWithValue('error');
        }
    },
);

interface ITrailerDetailInitialState {
    loading: boolean;
    trailerDetail: ITrailerDetail;
    draftTrailerDetail: ITrailerDetail;
    status: APIStatus;
    reassignTrailer: IReassignTrailer[];
}

const initialState: ITrailerDetailInitialState = {
    loading: false,
    trailerDetail: TRAILER_DETAIL_INITIAL_STATE,
    draftTrailerDetail: TRAILER_DETAIL_INITIAL_STATE,
    reassignTrailer: [],
    status: APIStatusOK,
};

export const fetchTrailerDetailSlice = createSlice({
    name: 'fetchTrailerDetail',
    initialState,
    reducers: {
        setTrailerDetail: (state, action: PayloadAction<ITrailerDetail>) => {
            state.trailerDetail = action.payload;
        },
        setReassignTrailer: (
            state,
            action: PayloadAction<IReassignTrailer[]>,
        ) => {
            state.reassignTrailer = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTrailerDetail.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchTrailerDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.trailerDetail = action.payload;
            state.draftTrailerDetail = action.payload;
        });
        builder.addCase(fetchTrailerDetail.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { setTrailerDetail, setReassignTrailer } =
    fetchTrailerDetailSlice.actions;

const TrailerDetailReducer = fetchTrailerDetailSlice.reducer;
export default TrailerDetailReducer;
