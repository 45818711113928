import { dispatchManagerFeatures } from '../../../../../featureFlags/dispatchManagerFeatures';

export interface IUserListUtils {
    userListFilterQueryString: (
        tenantId: string,
        userName?: string,
        userStatus?: boolean,
        role?: string,
    ) => string;
}

export class UserListUtils implements IUserListUtils {
    userListFilterQueryString = (
        tenantId: string,
        userName?: string,
        userStatus?: boolean,
        role?: string,
    ): string => {
        const { isHideIntimetecMailIdUsers } = dispatchManagerFeatures;

        const emailFilter = isHideIntimetecMailIdUsers
            ? `and not endswith(Username, 'intimetec.com')`
            : '';

        let filterQuery = `tenantId eq ${tenantId} and firstName ne null ${emailFilter} `;

        if (userName) {
            filterQuery += ` and (contains(firstName, '${userName}') or contains(lastName, '${userName}'))`;
        }

        if (userStatus !== undefined) {
            filterQuery += ` and enabled eq ${userStatus}`;
        }

        filterQuery += role
            ? ` and Roles/any(r: r/Name ne 'Driver' and r/Name eq '${role}')`
            : ` and Roles/any(r: r/Name ne 'Driver')`;

        if (filterQuery) {
            filterQuery = `&$filter=${filterQuery}`;
        }

        return filterQuery;
    };
}
