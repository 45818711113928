import { ISettingsDetails } from './interfaces/ISettingsDetails';

export const INITIAL_SETTINGS_DETAILS: ISettingsDetails = {
    companyDetails: {
        brokerFeeType: 'Fixed',
        brokerFeeAmount: '',
        companyName: '',
        emailAddress: '',
        phoneNumber: '',
        faxNumber: '',
        dispatchSupportNumber: '',
        companyAddress: '',
        city: '',
        state: '',
        zipCode: '',
        companyLogo: '',
        baseSettingId: 0,
        settlementReportBatchNumber: '',
    },
    communicationDetails: {
        isGreetingMessageIncluded: false,
        greetingMessage: '',
        isOrderNumberIncluded: false,
        isDateIncluded: false,
        isTimeFormat12Hr: false,
        isPinDropNameIncluded: false,
        isLocationInstructionIncluded: false,
        isEstimatedWeightIncluded: false,
        isEstimatedHeadCountIncluded: false,
        isCommodityIncluded: false,
        isDriverInstructionIncluded: false,
        isEndingMessageIncluded: false,
        endingMessage: '',
    },
    invoicingDetails: {
        isSendInvoices: false,
        invoiceRecipients: [],
        isQuickBooksConnected: false,
    },
    passwordExpiryMonths: 0,
};

export const BROKER_FEE_TYPE = 'BrokerFeeType';
export const BROKER_FEE_AMOUNT = 'BrokerFeeAmount';
export const COMPANY_NAME = 'CompanyName';
export const COMPANY_ADDRESS = 'CompanyAddress';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP_CODE = 'ZipCode';
export const PHONE_NUMBER = 'PhoneNumber';
export const DISPATCH_SUPPORT_NUMBER = 'DispatchSupportNumber';
export const FAX_NUMBER = 'FaxNumber';
export const EMAIL_ADDRESS = 'EmailAddress';
export const COMPANY_LOGO = 'CompanyLogo';
export const SETTLEMENT_REPORT_BATCH_NUMBER = 'SettlementReportBatchNumber';
export const IS_GREETING_MESSAGE_INCLUDED = 'IsIncludeGreetingMessage';
export const GREETING_MESSAGE = 'GreetingMessage';
export const IS_ORDER_NUMBER_INCLUDED = 'IsIncludeOrderNumber';
export const IS_DATE_INCLUDED = 'IsIncludeDate';
export const IS_TIME_FORMAT_12_HR = 'Is12HrTimeDisplayFormat';
export const IS_PIN_DROP_NAME_INCLUDED = 'IsIncludePinDropName';
export const IS_LOCATION_INSTRUCTION_INCLUDED = 'IsIncludeLocationInstructions';
export const IS_ESTIMATED_WEIGHT_INCLUDED = 'IsIncludeEstimatedWeight';
export const IS_ESTIMATED_HEAD_COUNT_INCLUDED = 'IsIncludeHeadCount';
export const IS_COMMODITY_INCLUDED = 'IsIncludeCommodity';
export const IS_DRIVER_INSTRUCTION_INCLUDED = 'IsIncludeDriverInstructions';
export const IS_ENDING_MESSAGE_INCLUDED = 'IsIncludeEndingMessage';
export const ENDING_MESSAGE = 'EndingMessage';
export const IS_SEND_INVOICE = 'IsSendInvoices';
export const INVOICE_RECIPIENTS = 'InvoiceRecipients';
export const IS_QUICKBOOKS_CONNECTED = 'IsQuickBooksConnected';
export const INSTRUCTIONS_FILE = 'Instructions';
export const QWC_CONNECTION_FILE = 'QWC';
export const ROD_INVOICE_TEMPLATE = 'InvoiceTemplate';
export const INSTRUCTIONS_FILE_NAME = 'Connect_To_QuickBooks_Instructions.pdf';
export const QWC_CONNECTION_FILE_NAME = 'ROD_QB_Config_file.qwc';
export const ROD_INVOICE_TEMPLATE_NAME = 'Roll_On_Dispatch_Template.DES';
export const PASSWORD_EXPIRATION_MONTH = 'PasswordExpirationMonths';

export const settingConstants = {
    tabs: {
        companyAndUserTab: 'Settings:companyAndUsers',
        brokerAndDriverTab: 'Settings:generalPreferences',
        communicationAndInvoice: 'Settings:communicationAndInvoices',
    },
};

export const UserStatus = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
];

export const UserRole = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Dispatcher', label: 'Dispatcher' },
    { value: 'Finance', label: 'Finance' },
];
